import React from 'react';
import { Button, Form, Modal } from 'antd';

const KCSFormModal = ({
  isOpenModal,
  title,
  content,
  closeButton,
  closeModal,
  confirmButton,
  confirmAction,
  size,
  form,
  layout,
}) => {
  return (
    <Modal
      className="form-modal"
      title={title || 'Thông báo'}
      visible={isOpenModal}
      onCancel={closeModal}
      footer={null}
      width={size === 'xl' ? 1150 : size === 'lg' ? 800 : 520}
    >
      <Form onFinish={confirmAction} form={form} layout={layout ? layout : 'horizontal'}>
        <div>{content}</div>
        <div className="flex justify-center space-x-4 mt-6">
          {closeButton && (
            <Button key="cancel" onClick={closeModal}>
              {closeButton || 'Cancel'}
            </Button>
          )}
          <Form.Item>
            <Button key="ok" type="primary" htmlType="submit">
              {confirmButton || 'OK'}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default React.memo(KCSFormModal);
