import { getRequest, postRequest } from 'utils/http';

const baseURL = `${process.env.REACT_APP_BACKEND_URL}/api/v1`;

export const ExperienceCampService = {
  getDataPaging: function (filterValue, successCallback, failCallback) {
    const URL = `${baseURL}/experience/camp/list-tab?filterValue=` + filterValue;
    return getRequest(URL, {}, successCallback, failCallback);
  },

  getDataById: function (id, successData, failCallback) {
    const URL = `${baseURL}/experience/camp/${id}`;
    return getRequest(URL, {}, successData, failCallback);
  },

  getAllData: function (successCallback, failCallback) {
    const URL = `${baseURL}/experience/camp/all`;
    return getRequest(URL, {}, successCallback, failCallback);
  },

  addNew: function (data, successCallback, failCallback) {
    const URL = `${baseURL}/experience/camp/add`;
    return postRequest(URL, data, {}, successCallback, failCallback);
  },

  update: function (data, successCallback, failCallback) {
    const URL = `${baseURL}/experience/camp/update`;
    return postRequest(URL, data, {}, successCallback, failCallback);
  },
  updateStatus: function (id, newStatus, successCallback, failCallback) {
    const URL = `${baseURL}/experience/camp/update/${id}/${newStatus}`;
    return postRequest(URL, {}, {}, successCallback, failCallback);
  },
  updateStatusWithBody: function (id, data, successCallback, failCallback) {
    const URL = `${baseURL}/experience/camp/update/${id}`;
    return postRequest(URL, data, {}, successCallback, failCallback);
  },
  delete: function (data, successData, failCallback) {
    const URL = `${baseURL}/experience/camp/delete/${data.id}`;
    return postRequest(URL, data, {}, successData, failCallback);
  },
};
