import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Layout, { Header } from 'antd/es/layout/layout';
import { Button, Form, Input, message, Slider, Upload } from 'antd';
import { Helmet } from 'react-helmet';
import { InboxOutlined } from '@ant-design/icons';
import { ExperienceCampService } from '../../api/ExperienceCampService';
import { ExperienceService, uploadFileExperienceURL } from '../../api/ExperienceService';
import Cookies from 'universal-cookie';
import md5Hex from 'md5-hex';

const cookies = new Cookies();
const PublicExperiencePage = () => {
  const [status, setStatus] = useState(0);
  const [exCamp, setExCamp] = useState({});
  const [service, setService] = useState({});
  const [fileList, setFileList] = useState([]);
  const [form] = Form.useForm();

  const { exCampId } = useParams();

  cookies.set('AuthorizationPublic', md5Hex(exCampId));

  useEffect(() => {
    getDataById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDataById = () => {
    setStatus(0);
    ExperienceCampService.getDataById(
      exCampId,
      (res) => {
        if (res.data !== undefined && res.data !== null) {
          setExCamp(res.data);
          setService(res.data.serviceInfo);
          setStatus(1);
        }
      },
      (error) => {
        console.log(error);
        setStatus(0);
      }
    );
  };

  const handleChangeFile = (info) => {
    if (
      info.file !== undefined &&
      info.file !== null &&
      info.file.status === 'removed' &&
      info.file.response !== undefined
    ) {
      ExperienceService.removeByPath(
        info.file.response.data,
        (res) => {
          console.log('response delete file');
        },
        (error) => {
          console.log('error delete file');
          console.log(error);
        }
      );
    }
    if (info.fileList !== undefined && info.fileList !== null) {
      let mediaPath = '';
      info.fileList.forEach((element, index) => {
        if (element.status === 'done') {
          if (index === 0) mediaPath = element.response.data;
          else mediaPath = mediaPath + '|' + element.response.data;
        }
      });
      form.setFieldsValue({ exMediaPath: mediaPath });
    }
    setFileList(info.fileList);
  };

  const props = {
    name: 'file',
    multiple: false,
    action: uploadFileExperienceURL,
    fileList: fileList,
    onChange: handleChangeFile,
  };

  const handleAdd = (data) => {
    data.exExCampId = exCampId;
    ExperienceService.addNew(
      data,
      (res) => {
        message.success('Đã tiếp nhận phản hồi trải nghiệm sản phẩm dịch vụ');
        setStatus(2);
      },
      (error) => {
        console.log(error);
        message.success('Có lỗi xảy ra trong khi tiếp nhận phản hồi trải nghiệm sản phẩm dịch vụ');
        setStatus(2);
      }
    );
  };

  const marks = {
    0: {
      style: {
        color: '#FF0000',
      },
      label: <strong>0</strong>,
    },
    100: {
      style: {
        color: '#00FF00',
      },
      label: <strong>100</strong>,
    },
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header />
      <div className="bg-purple-50 min-h-full">
        <div className="min-h-screen -mt-14 text-base">
          <Helmet>
            <title>{exCamp.exCampName}</title>
          </Helmet>

          <div className="mx-auto w-full lg:w-1/2 servey">
            {/*<Form className="servey" useForm={form} onFinish={handleAdd} layout={"vertical"}>*/}
            <section className="shadow bg-white py-5 px-8 mt-6 border rounded-lg">
              <div className="text-lg text-center" hidden={status !== 0}>
                <p>Chiến dịch trải nghiệm người dùng sản phẩm, dịch vụ đã hết hiệu lực hoặc không tồn tại</p>
              </div>
              <div hidden={status !== 1}>
                <h2 className="text-xl lg:text-3xl text-center uppercase">Phản hồi trải nghiệm sản phẩm, dịch vụ</h2>
                <div className="text-left">
                  <strong>Chiến dịch trải nghiệm</strong>
                  <br />
                  {exCamp.exCampName}
                  <br />
                  <strong>Dịch vụ trải nghiệm</strong>
                  <br />
                  {service.sname}
                </div>
                <Form className="servey" useForm={form} onFinish={handleAdd} layout={'vertical'}>
                  <Form.Item name="exExCampId" hidden>
                    <Input name="exExCampId" value={exCampId} />
                  </Form.Item>
                  <br />
                  <Form.Item name="exInput" label="Thông tin trải nghiệm" rules={[{ required: true }]}>
                    <Input.TextArea placeholder="Thông tin trải nghiệm" name="exInput" rows={5} />
                  </Form.Item>
                  <br />
                  <Form.Item name="exOutput" label="Kết quả thực tế" rules={[{ required: true }]}>
                    <Input.TextArea placeholder="Kết quả thực tế" name="exOutput" rows={5} />
                  </Form.Item>
                  <br />
                  <Form.Item name="exResult" label="Kết quả mong muốn" rules={[{ required: true }]}>
                    <Input.TextArea placeholder="Kết quả mong muốn" name="exResult" rows={5} />
                  </Form.Item>
                  <br />
                  <Form.Item name="exScore" label="Điểm số trải nghiệm" rules={[{ required: true }]}>
                    <Slider min={0} max={100} marks={marks} tooltipVisible={true} name="exScore" />
                  </Form.Item>
                  <br />
                  <Form.Item name="exComments" label="Góp ý" rules={[{ required: true }]}>
                    <Input.TextArea placeholder="Góp ý" name="exComments" rows={5} />
                  </Form.Item>
                  <Form.Item name="exMediaPath" label="Tài liệu đính kèm">
                    <Upload {...props}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">Kéo thả tài liệu cần tải lên vào đây</p>
                    </Upload>
                  </Form.Item>
                  <div className="flex justify-center mt-6">
                    <Form.Item>
                      <Button
                        htmlType="submit"
                        type="primary"
                        style={{
                          padding: '20px 40px',
                          display: 'flex',
                          alignItems: 'center',
                          borderRadius: '10px',
                        }}
                      >
                        Gửi
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
              </div>
              <div className="text-lg text-center " hidden={status !== 2}>
                <p>Chúng tôi đã nhận được thông tin khảo sát của bạn.</p>
                <p>Trân trọng cảm ơn!</p>
              </div>
            </section>
            {/*</Form>*/}
          </div>
        </div>
      </div>
      <footer className="text-center border-t p-4 shadow">
        © Bản Quyền thuộc Tập đoàn Bưu chính Viễn thông Việt Nam
      </footer>
    </Layout>
  );
};

export default React.memo(PublicExperiencePage);
