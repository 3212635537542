import React, { useState } from 'react';
import { Form, Input } from 'antd';
import { CommonService } from 'api/CommonService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/fontawesome-free-solid';
import './style.scss';

const InputForm = ({
  placeholder,
  customInput,
  customClass,
  name,
  hidden,
  disabled,
  isRequired,
  helpText,
  isPhoneNumber,
  onlyNumber,
  textRequire,
  isEmail,
  isPassword,
  onChange,
  requireNumber,
  isMaxLength,
  maxLength,
  label,
}) => {
  let rules = [];
  const [visible, setVisible] = useState(true);
  if (requireNumber) {
    rules = [
      {
        pattern: CommonService.REGEX_ONLY_NUMBER,
        message: 'Chỉ được phép nhập số',
      },
      ...rules,
    ];
  }
  if (isRequired) {
    rules = [
      {
        required: true,
        message: textRequire || 'Trường bắt buộc không được để trống',
      },
      ...rules,
    ];
  }
  if (isPhoneNumber) {
    rules = [
      {
        pattern: CommonService.REGEX_PHONE_NUMBER,
        message: 'Số điện thoại không hợp lệ',
      },
      ...rules,
    ];
  }
  if (isMaxLength) {
    rules = [
      {
        pattern: CommonService.REGEX_MAX_LENGTH,
        message: 'Giá trị phải nhỏ hơn 255 ký tự',
      },
      ...rules,
    ];
  }

  if (isEmail) {
    rules = [
      {
        pattern: CommonService.REGEX_EMAIL,
        message: 'Email invalidate',
      },
      ...rules,
    ];
  }

  return (
    <Form.Item className={`input-common ${customClass || ''}`} hidden={hidden} label={label}>
      <Form.Item name={name} noStyle rules={rules}>
        <Input
          size="large"
          placeholder="&nbsp;"
          autoComplete="true"
          className={`${customInput || ''} custom-input`}
          type={isPassword && visible ? 'password' : isPhoneNumber || onlyNumber ? 'number' : 'text'}
          onChange={onChange}
          disabled={disabled}
          maxLength={maxLength}
        />
      </Form.Item>
      <span className="input-common__placeholder">{placeholder}</span>
      <p className="input-common__help-text pl-4 pt-1 mb-0">{helpText}</p>
      {isPassword && (
        <div className="absolute inset-y-0 right-4 pr-3 flex items-center text-sm leading-5">
          <FontAwesomeIcon
            icon={visible ? faEye : faEyeSlash}
            className="text-2xl"
            onClick={() => setVisible(!visible)}
          />
        </div>
      )}
    </Form.Item>
  );
};

export default React.memo(InputForm);
