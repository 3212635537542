import React, { useEffect } from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';

import './css/index.scss';
import 'antd/dist/antd.css';

import { useStore } from './redux';
import { Provider } from 'react-redux';
import AppWrapper from 'pages/AppWrapper';

function App() {
  const location = useLocation();
  const store = useStore();

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto';
    window.scroll({ top: 0 });
    document.querySelector('html').style.scrollBehavior = '';
  }, [location.pathname]); // triggered on route change

  return (
    <Router>
      <Provider store={store}>
        <AppWrapper />
      </Provider>
    </Router>
  );
}

export default App;
