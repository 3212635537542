import React from 'react';
import { Select } from 'antd';
import PropTypes from 'prop-types';

const { Option, OptGroup } = Select;

const SelectBox = ({ list, showSearch, placeholder, handleChangeFilter, style, className, size }) => {
  return (
    <Select
      defaultValue={list[0].children.length > 0 ? list[0].children[0].value : list[0].value}
      onChange={handleChangeFilter}
      showSearch={showSearch}
      size={size}
      placeholder={placeholder}
      filterOption={(input, option) =>
        option.children && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      style={style}
    >
      {list.map((item, index) => (
        <React.Fragment key={index}>
          {item.children.length > 0 ? (
            <OptGroup label={item.text}>
              {item.children.map((itemChild, indexChild) => (
                <Option key={indexChild} value={itemChild.value}>
                  {itemChild.text}
                </Option>
              ))}
            </OptGroup>
          ) : (
            <Option value={item.value}>{item.text}</Option>
          )}
        </React.Fragment>
      ))}
    </Select>
  );
};

SelectBox.propTypes = {
  list: PropTypes.array.isRequired,
};

SelectBox.defaulProps = {
  size: 'default',
  list: [],
};

export default React.memo(SelectBox);
