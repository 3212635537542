import {
  ADD_USER_INFO,
  REMOVE_USER_INFO,
  GET_INFO,
  GET_MENU_PERMISSIONS,
  REMOVE_MENU_PERMISSIONS,
} from 'redux/constants';

export const fetchUser = (data) => (dispatch) => {
  dispatch({
    type: ADD_USER_INFO,
    payload: data,
  });
};

export const setInfoUser = (data) => (dispatch) => {
  dispatch({
    type: GET_INFO,
    payload: data,
  });
};

export const removeUser = () => (dispatch) => {
  dispatch({
    type: REMOVE_USER_INFO,
  });
};

export const setMenuPermissions = (data) => (dispatch) => {
  dispatch({
    type: GET_MENU_PERMISSIONS,
    payload: data,
  });
};

export const removePermission = () => (dispatch) => {
  dispatch({
    type: REMOVE_MENU_PERMISSIONS,
  });
};
