import { ADD_MORE_USER_MODAL } from './UserConstant';

const initialState = {
  listUserManage: [
    {
      index: 1,
      groupId: '',
      groupRole: '',
    },
  ],
  constants: 1,
};

export function UserManageReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_MORE_USER_MODAL:
      return {
        ...state,
        listUserManage: action.payload,
      };
    default:
      return state;
  }
}
