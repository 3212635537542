import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cui from '../cui/cui';
import icon_01 from 'assets/images/icon-01.svg';
import icon_02 from 'assets/images/icon-02.svg';
import './style.scss';

const regexNumb = /^[0-9]*$/;

const InputText = ({
  value,
  name,
  title,
  handleOnChange,
  onEnter,
  autoFocus,
  placeholder,
  extensionClass,
  onlyNumber,
  isPassword,
  errorMsg,
  customClass,
  customTitle,
}) => {
  const [visible, setVisible] = useState(true);

  const onChange = (e) => {
    const { value } = e.target;

    if (!onlyNumber || value === '') {
      handleOnChange(value, name);
      return;
    }

    if (regexNumb.test(value)) {
      handleOnChange(parseInt(value), name);
    }
  };

  const handleOnKeyDown = (e) => {
    if (e.keyCode === 13) {
      onEnter();
    } else if (e.keyCode === 38 || e.keyCode === 40) {
      e.preventDefault();
    }
  };

  return (
    <>
      <div className={`input-text ${customClass || ''}`}>
        {title && <span className={`input-text__title ${customTitle || ''}`}>{title}</span>}
        <input
          type={onlyNumber ? 'number' : isPassword && visible ? 'password' : 'text'}
          className={`${extensionClass || ''} ${errorMsg && 'border-red'}`}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          onKeyDown={handleOnKeyDown}
          autoFocus={autoFocus}
        />
        {isPassword && <img alt="icon" src={visible ? icon_01 : icon_02} onClick={() => setVisible(!visible)} />}
      </div>
      {!cui.isUndefinedOrNull(errorMsg) && <div className="err-msg">{errorMsg}</div>}
    </>
  );
};

InputText.propTypes = {
  handleOnChange: PropTypes.func,
  onEnter: PropTypes.func,
};

InputText.defaultProps = {
  value: '',
  handleOnChange: () => {},
  onEnter: () => {},
};

export default React.memo(InputText);
