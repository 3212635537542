import { Card, Col, Image, Row } from 'antd';
import { GalleryManagementService } from 'api/GalleryManagementService';
import React, { useEffect, useState } from 'react';

function PersonalFile({ props, clear = false, handlePersonalFile }) {
  const [gallerysList, setGallerysList] = useState([]);
  const [activeImg, setActiveImg] = useState(null);

  useEffect(() => {
    getListGallery();
  }, []);

  useEffect(() => {
    if (clear) {
      setActiveImg(null);
    }
  }, [clear]);

  const getListGallery = () => {
    GalleryManagementService.getListGallery((res) => {
      if (res.code === 0) {
        setGallerysList(res.galleryTransformList);
      }
    });
  };

  const onClickImage = (value, index) => {
    if (index === activeImg) {
      setActiveImg(null);
      handlePersonalFile(null);
    } else {
      setActiveImg(index);
      handlePersonalFile(value);
    }
  };

  return (
    <Image.PreviewGroup>
      <Row gutter={[16, 16]}>
        {gallerysList.map((item, index) => (
          <Col key={index} span={4}>
            <Card
              className={`${activeImg === index ? 'border-2 border-blue-600' : ''} cursor-pointer`}
              key={index}
              title={item.gname}
            >
              <Image
                width={100}
                height={100}
                key={index}
                preview={false}
                onClick={() => onClickImage(item, index)}
                src={item.linkImg}
              />
            </Card>
          </Col>
        ))}
      </Row>
    </Image.PreviewGroup>
  );
}

export default PersonalFile;
