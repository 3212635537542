export const SHOW_PROGRESS = 'SHOW_PROGRESS';
export const HIDE_PROGRESS = 'HIDE_PROGRESS';
export const RESET_PROGRESS = 'RESET_PROGRESS';

export const ADD_USER_INFO = 'ADD_USER_INFO';
export const GET_INFO = 'GET_INFO';
export const REMOVE_USER_INFO = 'REMOVE_USER_INFO';
export const GET_MENU_PERMISSIONS = 'GET_MENU_PERMISSIONS';
export const REMOVE_MENU_PERMISSIONS = 'GET_MENU_PERMISSIONS';

export const ADD_PROJECT_INFO = 'ADD_PROJECT_INFO';
export const REMOVE_PROJECT_INFO = 'REMOVE_PROJECT_INFO';

export const CHANGE_LOCALE = 'CHANGE_LOCALE';
export const DEFAULT_LOCALE = 'vi';

export const CHANGE_QUESTION_SURVEY = 'CHANGE_QUESTION_SURVEY';
export const CHANGE_CONSTANTS_SURVEY = 'CHANGE_CONSTANTS_SURVEY';
export const CHANGE_CHECKBOX_QUES_SURVEY = 'CHANGE_CHECKBOX_QUES_SURVEY';
export const CHANGE_RADIO_QUES_SURVEY = 'CHANGE_RADIO_QUES_SURVEY';
export const CHANGE_LIST_RADIO_QUES_SURVEY = 'CHANGE_LIST_RADIO_QUES_SURVEY';
export const CHANGE_LIST_CHECKBOX_QUES_SURVEY = 'CHANGE_LIST_CHECKBOX_QUES_SURVEY';
export const CHANGE_LINEAR_RANGE = 'CHANGE_LINEAR_RANGE';
export const CHANGE_STAR = 'CHANGE_STAR';
export const CHANGE_AIS_REQUIRE = 'CHANGE_AIS_REQUIRE';
export const CHANGE_SECTION_SURVEY = 'CHANGE_SECTION_SURVEY';
export const CHANGE_CONSTANTS_SECTION_SURVEY = 'CHANGE_CONSTANTS_SECTION_SURVEY';
