import React from 'react';
import './style.scss';
import { Form, Select } from 'antd';
import PropTypes from 'prop-types';

const { Option } = Select;

const DropdownForm = ({ placeholder, list, customClass, name, isRequired, handleChange, initialValue, style }) => {
  let rules = [];
  if (isRequired) {
    rules = [
      {
        required: true,
        message: 'Trường bắt buộc không được để trống',
      },
      ...rules,
    ];
  }

  return (
    <Form.Item className={`dropdown-form ${customClass || ''}`} name={name} rules={rules} initialValue={initialValue}>
      <Select size="large" placeholder={placeholder} style={style} onChange={handleChange}>
        {list.map((item, index) => (
          <Option value={item.value} key={index}>
            {item.text}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

DropdownForm.propTypes = {
  list: PropTypes.array,
};

DropdownForm.defaultProps = {
  list: [],
};

export default React.memo(DropdownForm);
