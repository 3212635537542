import React, { useState } from 'react';
import Paragraph from 'antd/es/typography/Paragraph';
import { Tooltip } from 'antd';

const TooltipParagraph = ({ children, ellipsis, ...props }) => {
  const [truncated, setTruncated] = useState(false);

  return (
    <Tooltip
      placement="right"
      overlayStyle={{
        paddingLeft: 45,
      }}
      title={truncated ? children : undefined}
    >
      <Paragraph {...props} className="text-white mb-0" ellipsis={{ ...ellipsis, onEllipsis: setTruncated }}>
        <>{children}</>
      </Paragraph>
    </Tooltip>
  );
};

export default TooltipParagraph;
