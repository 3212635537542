import React from 'react';

const Title = ({ text }) => {
  return (
    <div className="text-xl m-0">
      <p className="mb-0">{text}</p>
    </div>
  );
};

export default React.memo(Title);
